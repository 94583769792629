<template>
  <div id="newsStoryContent">
    <p id="Text1"></p>
    <p align="left">
      We’ve been at E3, the Electronic Entertainment Expo, for two days now, and we’ve been
      having a blast! We kicked off the official countdown for LEGO Universe at E3 when we
      revealed the game’s official launch date—mark your calendars for October 26, 2010!
      <br>
      <br>
      <!-- picDF54B84A6E94EE69BBFF6690B16C4C7F.jpg -->
      <img class="rounded img-small" src="">
      &nbsp;&nbsp;&nbsp;&nbsp;
      <!-- pic1EE947FC50B740AFC4571565E645E51C.jpg -->
      <img class="rounded img-small" src="">
      <br>
      <br>
      <!-- pic05FAE5503259A884E80C5695ECC6A2D4.jpg -->
      <img class="rounded img-small" src="">
      &nbsp;&nbsp;&nbsp;&nbsp;
      <!-- pic1687087C8A21C8582850C87758C0B131.jpg -->
      <img class="rounded img-small" src="">
      <br>
      <br>
      It’s been exciting to give live demos of LEGO Universe to fans and E3 attendees. There are so
      many creative brick-building possibilities fans can look forward to when the game launches.
      <br>
      <br>
      We are proud to announce that LEGO Universe has received a GamePro E3 award nomination!
      While at E3, we’re showcasing the building and behaviors features of the game. Our crazy,
      creative developers have been building creatures to battle each other—we’ve even seen dinosaurs
      battling chickens! You’ll be able to create anything your imagination can dream up and then
      bring it to life!
      <br>
      <br>
      We saw this sign at the E3 entrance and couldn’t resist taking a picture since it’s what
      LEGO Universe is all about…get ready to unleash your creativity!
      <br>
      <br>
      <!-- picA5045214A1AE081D0DAD4176B4683D87.jpg -->
      <img class="rounded img-large" src="">
    </p>
    <p></p>
  </div>
</template>
